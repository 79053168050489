@use '@angular/material' as mat;
@use "sass:map";

@mixin portal-typography-theme($theme) {
    $background: map.get($theme, background);

    .portal-typography {
        background: mat.m2-get-color-from-palette($background, app-bar);
    }
}
