@use 'color-palette';

@mixin portal-stepper($theme) {
  .mat-stepper-horizontal.wizard {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 100%;

    .mat-horizontal-stepper-header-container {
      background-color: transparent;
      pointer-events: none;
    }

    .mat-horizontal-content-container {
      overflow-y: auto;
      padding: 20px;
      flex: 1;
      background-color: transparent;

      .mat-horizontal-stepper-content {
        width: 100%;
        //overflow: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
      }

      .actions {
        padding: 4px 0;
      }
    }
  }
}
