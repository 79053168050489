@use 'color-palette';
@use "sass:map";

@mixin tabs($theme) {
  $background: map.get($theme, background);
  .ee__tabs.mat-mdc-tab-header, .ee__tabs.mat-mdc-tab-group {
    .mat-mdc-tab-link-container .mat-mdc-tab-list, .mat-mdc-tab-label-container .mat-mdc-tab-list {
      border-bottom: .5px solid rgba(97, 97, 97, .5);

      .mat-mdc-tab-links .mdc-tab, .mat-mdc-tab-labels .mdc-tab {
        flex-grow: 0;

        .ee__tabs-header, .mdc-tab__text-label {
          font-size: 16px;
          line-height: 18px;
          color: #424242;
          font-weight: 600;
        }

        .ee__tabs-subheader {
          font-size: 10px;
          line-height: 18px;
          color: #6F6F6F;
        }

        //.mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        //  border-color: var(--primary-color);
        //  opacity: .1;
        //  border-width: .5px;
        //}

        &.mdc-tab--active {
          background-color: rgba(213, 0, 0, .05);
          .ee__tabs-header, .mdc-tab__text-label {
            color: var(--primary-red);
          }

          .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
            border-color: var(--primary-red);
            opacity: 1;
          }
        }
      }
    }
  }

  .mat-mdc-tab-nav-bar.mat-primary.blue, .mat-mdc-tab-group.blue .mat-mdc-tab-header {
    background-color: var(--primary-blue);

    .mdc-tab {
      flex-grow: 0;

      &.mdc-tab--active {
        border-bottom: 2px solid #fff;
        margin-bottom: 1px;
      }
    }

    .mdc-tab__text-label {
      color: var(--c-white);
      opacity: .5;
    }

    .mdc-tab--active .mdc-tab__text-label {
      opacity: 1;
    }

    .mat-mdc-tab-links, .mat-mdc-tab-labels {
      a.mat-mdc-tab-link, .mat-mdc-tab-label-content {
        color: var(--c-white);

        &.mat-mdc-tab-label-active {
          opacity: 1;
        }
      }
    }

    .mat-mdc-ink-bar {
      background-color: var(--c-white);
    }
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }


  mat-tab-group {
    &.action-panel-tabs {
      .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
      }

      .mdc-tab.mat-mdc-tab {
        min-width: 80px;
        height: 36px;
        padding: 0 18px;
        font-size: 14px;
        flex-grow: 0 !important;

        .mat-mdc-tab-label-content {
          user-select: none;
        }

        &:hover {
          background-color: var(--c-gray-95);
        }

        &.mdc-tab--active, &.mdc-tab--active:hover {
          background-color: var(--c-gray-90);
          color: #000;
          opacity: 1;
        }
      }

      .mat-mdc-tab-body {
        margin: 1rem;
      }
    }
  }

  @media screen and (max-width: var(--break-lg) - 1) {
    .sidepanel {
      mat-mdc-tab-group {
        .mat-mdc-tab-labels {
          .mat-mdc-tab-label {
            min-width: 100px;
          }
        }
      }
    }
  }
}
