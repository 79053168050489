@use '@angular/material' as mat;
@use "sass:map";

@mixin tooltip($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  .mtx-popover-panel {
    max-width: 300px;
    background-color: mat.m2-get-color-from-palette($background, default);
    color: mat.m2-get-color-from-palette($foreground, default);

    &.dark {
      background-color: #424242;
      color: #fff;

      .mtx-popover-direction-arrow {
        &::after {
          border-top-color: #424242;
          border-bottom-color: #424242;
        }
      }
    }

    &.small {
      font-size: .875rem;
    }
  }
}
