@use '@angular/material' as mat;
@import './color-palette';
@import '../responsive.mixin';

@mixin inputs() {
  .ee-remove-fixed-infix-width .mat-mdc-form-field-infix {
    width: auto;
  }

  .hide-label-gap {
    .mat-mdc-form-field-infix {
      border-top: 0;
    }
  }

  .mat-mdc-form-field {
    .field-instructions-wrapper {
      margin-left: -2px;
      .field-instructions {
        color: var(--primary-red);
        text-decoration: underline;
        text-decoration-style: dashed;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }

  .mat-mdc-form-field.hidden-from-intake .mat-mdc-form-field-flex {
    background-color: #ededed;
  }

  .city-state-zip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    mat-form-field {
      margin-bottom: 1rem;
      flex: 1 1 100%;
    }
  }


  @media screen and (min-width: var(--break-md)) {
    .city-state-zip {
      flex-direction: row;
      justify-content: stretch;

      mat-form-field {
        margin-right: 1rem;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

}
