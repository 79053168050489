@use '@angular/material' as mat;
@use 'ee-common' as common;
@use 'components/tagify';
@use 'components/color-palette';
@use 'components/theming';
@use 'responsive.mixin.scss';
$theme: common.generateLightTheme();
@include common.setup($theme);

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

.scroll-y {
  overflow-y: auto;
  height: 100%;
}

.no-scroll-y {
  overflow-y: hidden;
}

.no-scroll-x {
  overflow-x: hidden;
}

.scroll-x {
  overflow-x: auto;
}

.cursor-pointer {
  cursor: pointer;
}
