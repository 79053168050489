@use 'color-palette';
@use '../responsive.mixin' as responsive;

@mixin dialog($theme) {
  mat-dialog-container.mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
    min-height: 100%;

    .mat-mdc-dialog-title {
      background-color: var(--dark-gray);
      color: #fff;
      margin: 0;
      padding: 0 .875rem .875rem .875rem;
    }

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 24px !important;
      display: flex;
      flex-direction: column;
    }

    .mat-mdc-dialog-actions {
      border-top: 1px solid var(--c-gray-70);
      margin: 0;
      padding: 8px 16px;

      &.ee-modal-actions-split {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        &.justify-end {
          justify-content: flex-end;
        }

        .ee-modal-actions-split-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: .5rem;

          button {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: .5rem;
            width: 100%;
          }
        }
      }

      &.ee-modal-actions, .ee-modal-actions {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: stretch;

        button {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: .5rem;
        }
      }
    }

    @media screen and (min-width: responsive.$break-sm) {
      .mat-mdc-dialog-actions.ee-modal-actions-split {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .ee-modal-actions-split-left {
          align-items: start;
          margin-bottom: 0;
          flex: 1;

          .error {
            flex-direction: row;
            justify-items: start;
          }

          button {
            width: inherit;
            margin-bottom: 0;
          }
        }

        .ee-modal-actions {
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          button {
            margin-bottom: 0;
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .mat-mdc-dialog-actions.ee-modal-actions, .ee-modal-actions {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button {
          margin-bottom: 0;
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
