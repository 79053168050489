@use 'color-palette';

@mixin portal-button-toggle-group($theme) {
  .mat-mdc-button-toggle-group {
    .mat-mdc-button-toggle-checked {
      color: var(--c-white);
      background-color: var(--primary-color);
    }
  }
}
