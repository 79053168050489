@use 'color-palette';

@mixin fab($theme) {
  .fab-container {
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 5px;

      button {
        margin-bottom: 17px;
      }
    }
  }

  .fab-start-here {
    position: fixed;
    bottom: 1.5rem;
    right: 9rem;
    text-align: right;
    z-index: 49;

    .message {
      font-size: 40px;
      line-height: 40px;
      text-align: left;
    }

    .arrow {
      font-size: 20vw;
      height: 20vw;
      line-height: 20vw;
    }

    &.top-left {
      top: 7.5rem;
      left: 6rem;
      bottom: auto;
      right: auto;
      display: flex;
      place-content: flex-end;
      align-items: flex-end;

      .message {
        font-size: 4rem;
        line-height: 6rem;
        text-align: center;
        margin-left: 1rem;
      }

      .arrow {
        transform: rotate(90deg);
        font-size: 16vw;
        height: 16vw;
        line-height: 16vw;
      }
    }
  }
}
