@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use 'components/color-palette';

@import 'components/fonts';
@import 'components/theming';
@import 'components/landing-theme';
/* Portal Global  */
@import 'components/typography';
@import 'components/button';
@import 'components/theming';
@import 'components/menus';
@import 'components/backgrounds';
@import 'components/inputs';
@import 'components/toolbar';
@import 'components/scrollbars';
@import 'components/rtl';
@import 'components/theme-icon';
@import 'components/loader';
@import 'components/checkbox';
@import 'components/colors-theme';
@import 'components/typography-theme';
@import 'components/theme-dashboard';
@import 'components/inbox';
@import 'components/timeline';
@import 'components/chips';
@import 'components/datagrid';
@import 'components/stepper';
@import 'components/button-toggle-group';
@import 'components/tabs';
@import 'components/timepicker';
@import 'components/card';
@import 'components/fab';
@import 'components/list';
@import 'components/search';
@import 'components/dialog';
@import 'components/dropzone';
@import 'components/radio';
@import 'components/guided-tour';
@import 'components/tooltip';
@import 'components/utils';
@import 'components/tagify';
@import 'components/section-styles';
@import 'components/toast';
@import 'components/quill';
@import 'components/ng-select';

@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/light';

$app-primary: mat.m2-define-palette(mat.$m2-gray-palette, A700, A100, A400);
$app-accent: mat.m2-define-palette(mat.$m2-red-palette, A700, A100, A400);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$typography: mat.m2-define-typography-config(
  $font-family: 'Barlow, sans-serif',
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(14px, 28px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 600),
  $body-1: mat.m2-define-typography-level(14px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 24px, 400),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500)
);

@mixin portal-theme($theme, $typography) {

  @include mtx.all-component-themes($theme);

  /* Portal Typography */
  @include portal-typography();

  @include loader($theme);

  /* Menu Utility Classes */
  @include portal-menus($theme);

  /* Input Classes */
  @include inputs();

  /* Toolbar Classes */
  @include portal-toolbar($theme);

  /* Coloured background classes */
  @include portal-backgrounds($theme);

  /* Hide scrollbars classes and perfect scrollbar style */
  @include portal-scrollbars();

  /* RTL */
  @include portal-rtl();

  /* Portal Timeline */
  @include portal-timeline($theme);

  /* Portal Stepper */
  @include portal-stepper($theme);

  /* Portal Button Toggle Group */
  @include portal-button-toggle-group($theme);

  /* Portal Typography Theme */
  @include portal-typography-theme($theme);

  @include landing-theme($theme);
  @include chips($theme);
  @include checkbox($theme);

  @include dataGrid($theme);
  @include tabs($theme);
  @include portal-button($theme);
  @include timepicker($theme);
  @include card($theme);
  @include fab($theme);
  @include list($theme);
  @include search($theme);
  @include dialog($theme);
  @include dropzone($theme);
  @include radio($theme);
  @include guidedTour($theme);
  @include tooltip($theme);
  @include tagify($theme);
  @include toast($theme);
  @include quill($theme);
  @include ng-select($theme);
}

@function generateLightTheme() {
  @return mat.m2-define-light-theme((
    color: portal-light-theme($app-primary, $app-accent, $app-warn),
    typography: $typography,
    density: -2
  ));
}

@function generateDarkTheme() {
  @return mat.m2-define-dark-theme((
    color: portal-dark-theme($app-primary, $app-accent, $app-warn),
    typography: $typography,
    density: -2
  ));
}


@mixin my-form-field-density($density) {
  $field-typography: mat.m2-define-typography-config(
    $body-1: mat.m2-define-typography-level(12px, 24px, 400),
  );
  @include mat.m2-typography-level($field-typography, 'body-1');
  @include mat.form-field-density($density);
  //margin-bottom: -18px;   //  leaving a bottom margin 8px
}

@mixin setup($app-theme) {

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @include mat.core();

  // Create app styles
  @include mat.all-component-themes($app-theme);
  @include mat.all-component-densities(-2);
  @include mat.all-component-typographies($typography);
  @include mat.input-density(-5);
  @include mat.checkbox-density(-3);
  @include mat.menu-density(-4);
  @include mat.autocomplete-density(-4);
  @include mat.datepicker-density(-4);
  @include mat.select-density(-5);
  @include mat.button-density(0);
  @include portal-theme($app-theme, $typography);
  @include tagify($app-theme);
  @include chips($app-theme);
  @include mtx.all-component-themes($app-theme);

  .mat-mdc-form-field-icon-prefix {
    margin-left: 8px;
  }
  .mat-mdc-form-field-infix {
    padding-top: 18px !important;
    padding-bottom: 6px !important;

    label.mdc-floating-label.mat-mdc-floating-label {
      display: block !important;
      top: 20px !important;
      margin-top: 4px;

      &.mdc-floating-label--float-above {
        padding-top: 0;
      }
    }
  }

  // Create the theme object (a Sass map containing all of the palettes).
  $sidenav-theme: portal-dark-theme($app-primary, $app-accent, $app-warn);

  .portal-sidenav-theme {
    @include mat.all-component-colors($sidenav-theme);
    @include portal-theme($sidenav-theme, $typography);
  }


  .compact {
    @include my-form-field-density(-5);

    &.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding-top: 6px !important;
      padding-bottom: 0px !important;
    }

    .mat-mdc-form-field-infix {
      padding-top: 14px !important;
      padding-bottom: 0 !important;

      label.mdc-floating-label.mat-mdc-floating-label {
        margin-top: 2px;
      }
    }

    .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint {
      margin-top: -4px;
    }

    .mat-datepicker-toggle {
      button.mdc-icon-button.mat-mdc-icon-button {
        width: 32px;
        height: 32px;

        svg.mat-datepicker-toggle-default-icon {
          width: 18px;
          font-size: 18px;
          height: 18px;
        }
      }
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        padding: 0 8px 0 0;
      }
    }
  }

  .ee__red {
    color: var(--primary-red);
  }

  html {
    background-color: #ffffff;
  }

  html,
  body {
    font-family: 'Barlow', sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
