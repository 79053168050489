@use 'color-palette';

@mixin dropzone($theme) {
  .dropzone {
    width: 90%;
    text-align: center;
    border: 4px dashed #000;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    padding: 2rem 0;

    .mat-mdc-icon.upload {
      font-size: 100px;
      width: 100px;
      line-height: 100px;
      margin-bottom: 20px;
      height: 100px;
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    label {
      color: white;
      width: 183px;
      height: 44px;
      border-radius: 21.5px;
      background-color: var(--primary-red);
      padding: 8px 16px;
      cursor: pointer;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: #38424c;
    }

    &.selected {
      .mat-icon.upload {
        color: var(--primary-green);
      }
    }

    &.fileover {
      //animation: shake 1s;
      //animation-iteration-count: infinite;
      border-width: 4px;

      .mat-icon.upload {
        font-weight: 100;
      }

      h3 {
        font-weight: 700;
      }
    }
  }

  .files-list {
    margin-top: 1.5rem;

    .single-file {
      display: flex;
      padding: 0.5rem;
      justify-content: space-between;
      align-items: center;
      border: dashed 1px #979797;
      margin-bottom: 1rem;
      flex-grow: 1;

      img.delete {
        margin-left: 0.5rem;
        cursor: pointer;
        align-self: flex-end;
      }

      .name {
        font-size: 14px;
        font-weight: 500;
        color: #353f4a;
        margin: 0;
      }

      .size {
        font-size: 12px;
        font-weight: 500;
        color: #a4a4a4;
        margin: 0;
        margin-bottom: 0.25rem;
      }

      .info {
        width: 100%
      }
    }
  }

  /* Shake animation */
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
