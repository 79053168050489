@use '@angular/material' as mat;
@use "sass:map";

@mixin loader($theme) {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);

    $portal-logo-size: 64px;
    $portal-logo-factor: 0.8;
    $portal-logo-inner-factor: 0.2;
    $portal-logo-inner-color: mat.m2-get-color-from-palette($primary);
    $portal-logo-circle: $portal-logo-size * $portal-logo-factor;
    $portal-logo-inner-circle: $portal-logo-size * $portal-logo-inner-factor;
    $portal-animation-time: 6s;

    .app-loader-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        opacity: 1 !important;
        > span {
            display: block;
            font-size: 0.875em;
            margin-top: 12px;
            text-transform: uppercase;
        }
    }

    .ee-logo {
        display: inline-block;
        margin-top: 24px;
        width: $portal-logo-size;
        height: $portal-logo-size;
        animation: portal-fade-in 400ms 0.4s;
        animation-fill-mode: both;
    }

    .lds-grid {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
        div {
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: mat.m2-get-color-from-palette($accent);
            animation: lds-grid 1.2s linear infinite;
            &:nth-child(1) {
                top: 6px;
                left: 6px;
                animation-delay: 0s;
            }
            &:nth-child(2) {
                top: 6px;
                left: 26px;
                animation-delay: -0.4s;
            }
            &:nth-child(3) {
                top: 6px;
                left: 45px;
                animation-delay: -0.8s;
            }
            &:nth-child(4) {
                top: 26px;
                left: 6px;
                animation-delay: -0.4s;
            }
            &:nth-child(5) {
                top: 26px;
                left: 26px;
                animation-delay: -0.8s;
            }
            &:nth-child(6) {
                top: 26px;
                left: 45px;
                animation-delay: -1.2s;
            }
            &:nth-child(7) {
                top: 45px;
                left: 6px;
                animation-delay: -0.8s;
            }
            &:nth-child(8) {
                top: 45px;
                left: 26px;
                animation-delay: -1.2s;
            }
            &:nth-child(9) {
                top: 45px;
                left: 45px;
                animation-delay: -1.6s;
            }
        }
    }

    @keyframes lds-grid {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }
    }
}
