@use '@angular/material' as mat;
@use "sass:map";

@mixin ng-select($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  ng-select.ng-select.ng-select-multiple.round {
    padding-bottom: .5rem;

    .ng-select-container {
      .ng-value-container {
        padding-top: 0;
        border-top-width: 8px;

        .ng-value {
          border-radius: 16px;
          padding: 0 10px;

          .ng-value-label {
            font-size: 12px;
          }
        }
      }
    }
  }

  ng-select.ng-select .ng-select-container.ng-appearance-outline {
    background-color: transparent;

    .ng-value-container {
      z-index: 100;
    }

    &::after {
      border: solid 1px #9e9e9e;
      background-color: #fff;
    }

    &:hover::after {
      border-width: 1px;
    }
  }
}
