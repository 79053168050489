@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Medium/Barlow-Medium.woff2') format('woff2'),
  url('../../shared-assets/fonts/Medium/Barlow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Regular/Barlow-Italic.woff2') format('woff2'),
  url('../../shared-assets/fonts/Regular/Barlow-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Bold/Barlow-BoldItalic.woff2') format('woff2'),
  url('../../shared-assets/fonts/Bold/Barlow-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Light/Barlow-Light.woff2') format('woff2'),
  url('../../shared-assets/fonts/Light/Barlow-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Bold/Barlow-Bold.woff2') format('woff2'),
  url('../../shared-assets/fonts/Bold/Barlow-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/Regular/Barlow-Regular.woff2') format('woff2'),
  url('../../shared-assets/fonts/Regular/Barlow-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../shared-assets/fonts/SemiBold/Barlow-SemiBold.woff2') format('woff2'),
  url('../../shared-assets/fonts/SemiBold/Barlow-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

