@use '@angular/material' as mat;
@use '../responsive.mixin.scss';
@use 'color-palette';
@use "sass:map";

@mixin guidedTour($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  //mat.m2-get-color-from-palette($accent)
  ngx-guided-tour {
    .page-tour-step, .tour-step {

      .guided-tour-image {
        text-align: center;

        img {
          max-width: 250px;
        }
      }

      .tour-block {
        border-radius: 4px;

        .tour-buttons button {
          font-size: .9rem;

          &.next-button {
            border-radius: 4px;
            padding: 1rem;
            font-size: .9rem;
          }

          &.skip-button, &.link-button.skip-button, &.link-button.back-button {
            padding: 12px 14px;
          }
        }
      }
    }
  }
}
