@mixin portal-ui-icon-sizing($icon-size) {
    font-size: $icon-size;
    width: $icon-size * 1.5;
    height: $icon-size * 1.5;
    line-height: $icon-size * 1.5;
    text-align: center;
    padding: 0;
    flex-shrink: 0;
}

// Display classes
.portal-display-block {
    display: block;
}

$standard-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

.ee__standard-shadow {
  box-shadow: $standard-shadow;
}
