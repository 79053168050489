@use '@angular/material' as mat;
@use '../responsive.mixin.scss';
@use 'color-palette';
@use "sass:map";

@mixin radio($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  .mat-mdc-radio-group.ee {
    display: flex;

    .mat-mdc-radio-button {
      padding: 1rem 3rem 1rem 1rem;
      border: 1px solid var(--primary-color);
      border-radius: 8px;
      color: var(--primary-color);
      position: relative;

      .mdc-form-field, .mdc-label {
        cursor: pointer;
      }

      &:not(:first-child) {
        margin-left: .5rem;
      }

      &.mat-mdc-radio-checked {
        border-color: var(--primary-red);
        background-color: #fff;
      }

      .highlight {
        position: absolute;
        top: -10px;
        right: -5px;
        background-color: var(--primary-red);
        border: 1px solid var(--primary-red);
        border-radius: 6px;
        color: #fff;
        padding: 0 4px;
        font-size: 12px;
      }
    }
  }
}
