@use 'color-palette';

@mixin chips($theme) {
  $mat-chip-height: 32px;
  $mat-chip-color: #e0e0e0;

  .mat-chips {
    --chip-pad: 0.3em 0.5em;
    --chip-pad-hover: 0.4em .6em;
    --chip-border-radius: 0.25rem;
    --chip-line-height: 16px;

    --chip-color-red: 213,0,0;
    --chip-color-green: 0,170,0;
    --chip-color-blue: 69,87,187;
    --chip-color-yellow: 186,169,63;
    --chip-color-orange: 255,165,0;
    --chip-color-dark-orange: 213, 96, 0;
    --chip-color-purple: 132,84,253;
    --chip-color-teal: 0,128,128;
    --chip-color-black: 0,0,0;
    --chip-color-light-gray: 140,140,140;
    --chip-color-gray: 66,66,66;

    padding: 0;

    .mat-chip {
      margin-bottom: 0;
    }

    &.status {
      padding: 12px 0;

      &.p-0 {
        padding: 0;
      }

      .mat-chip {
        display: inline-block;

        vertical-align: top;
        box-sizing: border-box;
        max-width: 100%;
        padding: var(--chip-pad);
        line-height: var(--chip-line-height) !important;
        border-radius: var(--chip-border-radius);
        white-space: nowrap;
        transition: .13s ease-out;
        margin: 0 10px 0 0;
        background-color: rgba(var(--chip-color-gray), 0.15);
        color: #fff;
        font-weight: normal;

        &.whitespace-normal {
          white-space: normal;
        }

        &:last-of-type {
          margin: 0;
        }

        &::before {
          content: "";
          position: absolute;
          border-radius: inherit;
          z-index: -1;
          pointer-events: none;
          transition: 120ms ease;
        }

        &.grid {
          margin-top: -2px;
        }

        &.mat-chip-hover:hover {
          background: #ccc;
        }

        &.Paid, &.green, &.Success, &.cn-Open {
          background-color: rgba(var(--chip-color-green), 0.15);
          color: rgba(var(--chip-color-green), 1);
        }

        &.Pending, &.gray {
          background-color: rgba(var(--chip-color-gray), 0.15);
          color: #424242;
        }

        &.purple, &.Partial {
          background-color: rgba(var(--chip-color-purple), 0.15);
          color: rgba(var(--chip-color-purple), 1);
        }

        &.Draft, &.light-gray, &.cn-Closed {
          background-color: rgba(var(--chip-color-light-gray), 0.15);
          color: #424242;
        }

        &.Sent, &.blue {
          background-color: rgba(var(--chip-color-blue), 0.15);
          color: rgba(var(--chip-color-blue), 1);
        }

        &.Viewed, &.orange, &.Unpaid, &.Cancelled {
          background-color: rgba(var(--chip-color-orange), 0.15);
          color: rgba(var(--chip-color-orange), 1);
        }

        &.dark-orange, &.Deleted {
          background-color: rgba(var(--chip-color-dark-orange), 0.15);
          color: rgba(var(--chip-color-dark-orange), 1);
        }

        &.past-due, &.trial, &.Failure, &.Failed, &.red, &.cn-Voided, &.cn-Deleted {
          background-color: rgba(var(--chip-color-red), 0.15);
          color: rgba(var(--chip-color-red), 1);
        }
      }
    }
  }
}
