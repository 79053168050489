@use '../responsive.mixin.scss' as responsive;

.section-wrapper {
  display: grid;
  grid-gap: 1rem;

  &.small-gap {
    grid-gap: 8px;
  }

  .in-row-grid {
    display: grid !important;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .triple-grid {
    display: grid !important;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .total {
    grid-column: -2/auto;
  }
}


@media screen and (min-width: responsive.$break-md) {
  .section-wrapper {
    &.small-gap {
      grid-gap: 8px;
    }
    .in-row-grid {
      display: grid !important;
      grid-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      &.wider {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }

      .double-column {
        grid-column: span 2;
      }

      .triple-column {
        grid-column: span 3;
      }

      .quad-column {
        grid-column: span 4;
      }
    }
  }
}
