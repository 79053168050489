@mixin portal-scrollbars() {
    .portal-hide-scrollbars {
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
        opacity: 0.6;
    }
}
