@use '@angular/material' as mat;
@use 'color-palette';
@use "sass:map";

@mixin timepicker($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  .timepicker {

    .timepicker__header {
      background-color: var(--dark-gray) !important;
    }

    .clock-face__clock-hand {
      background-color: mat.m2-get-color-from-palette($accent) !important;

      &::after {
        background-color: mat.m2-get-color-from-palette($accent) !important;
      }

      &::before {
        border-color: mat.m2-get-color-from-palette($accent) !important;
      }
    }

    .clock-face__number {
      > span.active {
        background-color: mat.m2-get-color-from-palette($accent) !important;
      }
    }

    button:not(.timepicker-dial__item) {
      color: mat.m2-get-color-from-palette($primary) !important;
    }
  }
}
