@use '@angular/material' as mat;
@use "sass:map";

@mixin toast($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  hot-toast-container {
    .hot-toast-message {

      .download-alert {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px;
        font-size: 14px;

        .btn.btn-primary {
          background-color: mat.m2-get-color-from-palette($primary, default);
          color: mat.m2-get-color-from-palette($background, default);
          border: 1px solid mat.m2-get-color-from-palette($primary, default);
          padding: 10px;
        }
      }
    }

    .hot-toast-icon, .hot-toast-close-btn {
      align-self: center;
    }
  }
}
