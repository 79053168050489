@use '@angular/material' as mat;
@use "sass:map";

@mixin landing-theme($theme) {
  $background: map.get($theme, background);
  $primary: map.get($theme, primary);

  // landing page container settings
  .landing-container {
    overflow: hidden;
    height: 100%;

    .header {
      .attorney-logo {
        max-height: 52px;
        padding-right: 20px;
      }
    }

    .landing {
      padding-top: 12px;
      padding-bottom: 12px;
      overflow: auto;

      p {
        font-size: 16px;
      }

      .margin-right {
        margin-right: 12px;
      }
    }

    ul.next-steps {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        background-color: #fff;
        margin: 3px;

        &:first-child {
          z-index: 1;
          box-shadow: 0 2px 2px -1px var(--c-gray-70);
          border: 1px solid var(--c-gray-85);
          margin: 0;

          .icon {
            color: #008b00;
          }

          .description {
            .header {
              color: #008b00;
            }
          }
        }

        .icon,
        .description {
          vertical-align: top;
          color: var(--c-gray-60);
        }

        .icon {
          font-size: 3rem;
          padding: 0 2.5rem;
          color: var(--c-gray-85);
          line-height: 3rem;
          height: 3rem;
          width: 100px;
        }

        .description {
          padding: 1.5rem;
          font-size: 1.05rem;

          .header {
            font-weight: bolder;
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }

  // IE 11 fixes
  @media all and (-ms-high-contrast: none) {
    .landing-container {
      height: 100%;
    }
  }
}
