@use 'color-palette';

@mixin search($theme) {

  .search-container.mat-mdc-form-field {
    padding: 0 12px;
    max-width: 500px;
    margin-top: auto;
    margin-bottom: auto;

    .mat-mdc-text-field-wrapper {
      padding-left: 4px;

      .mat-mdc-form-field-infix {
        padding-bottom: 4px;
        height: 34px;
        min-height: 34px;
        padding-top: 6px !important;
      }

      input {
        width: 200px;
      }

      .mat-mdc-form-field-icon-prefix {
        margin-left: 4px;
      }
    }


    .mat-mdc-progress-bar {
      position: absolute;
    }
  }

  .mat-mdc-autocomplete-panel {
    .search-header {
      margin: 4px 1rem;
      font-weight: 600;
    }
  }

  .mat-mdc-option.search-result-row {
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:last-of-type {
      border-bottom: none;
    }

    .mdc-list-item__primary-text {
      display: flex;
      flex-direction: row;
    }

    .search-result-favicon {
      line-height: 20px;
      opacity: 0.8;
      text-align: center;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      display: flex;

      &.recent-search {
        margin-top: 7px;
      }
    }

    .search-result-text {
      line-height: 20px;
      font-size: 18px;
      padding: 6px;
      display: flex;
      flex-direction: column;

      .line-1 {
        font-weight: 500;
        line-height: 1.1rem;
        font-size: 1.1rem;

        b {
          font-weight: 400 !important;
        }
      }

      .line-2 {
        font-size: .8rem;
        font-weight: 400;
        line-height: .9rem;
      }

      .tag-wrapper {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        margin-left: -5px;
        margin-bottom: -5px;

        .tagify .tagify__tag .tagify__tag-text {
          max-width: 80px;
          overflow: hidden;
        }
      }
    }
  }
}
