@use "sass:map";

:root {
  --break-sm: 600px;
  --break-md: 960px;
  --break-lg: 1280px;
  --break-xl: 1920px;
}

$break-sm: 600px;
$break-md: 960px;
$break-lg: 1280px;
$break-xl: 1920px;

$breakpoints: (
  'phone': ( min-width:  599px ),
  'tablet-portrait': ( min-width:  600px ),
  'tablet-landscape': ( min-width:  960px ),
  'desktop': ( min-width:  1280px ),
  'big-desktop': ( min-width:  1920px )
) !default;

@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, `#{$breakpoint}` can not be found. "
      + "Available breakpoints are: #{map-keys(($breakpoints))}";
  }
}
