@use '@angular/material' as mat;
@use "sass:map";
@use "color-palette";

@mixin portal-menus($theme) {
  $background: map.get($theme, background);
  /* Utility classes */
  .mat-mdc-menu-panel.portal-menu-no-padding {
    .mat-mdc-menu-content {
      min-width: 250px;
      padding: 0;
    }
    min-height: auto;
  }

  // icon
  .mat-mdc-menu-item .mat-icon {
    &.fa, &.far {
      font-size: 18px;
    }
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.no-width-limit {
    max-width: inherit !important;

    .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
      width: 100%;
    }
  }

  .mat-mdc-menu-panel .mat-mdc-menu-content {
    a.mat-mdc-menu-item {
      &.mat-mdc-badge {
        .mat-mdc-badge-content {
          right: 4px;
          top: 33%;
          border-radius: 2px;
          height: 18px;
          line-height: 18px;
          width: 26px;
          font-weight: 600;
        }
      }
    }
  }

  .mat-mdc-menu-panel {
    .user-menu-switch-account .mat-mdc-badge-content {
      right: 8px !important;
      bottom: 12px;
    }

    .confirm-delete {
      padding: 4px 32px 10px 14px;
    }
  }

  .mat-mdc-menu-panel.portal-menu-no-max-width {
    max-width: initial;
  }

  button.mat-mdc-menu-item.portal-menu--selected {
    background-color: var(--c-gray-95);

    .mat-mdc-menu-item-text {
      font-weight: 600;
    }
  }

  .portal-menu--active {
    background: mat.m2-get-color-from-palette($background, hover);
  }
}
