@use "./color-utils";

:root {
  --primary-color: #616161;
  --primary-red: #d50000;
  --primary-green: #00aa00;
  --primary-dark-red: #ae0000;
  --primary-orange: #d56000;
  --primary-teal: #008080;
  --primary-blue: #4557bb;
  --primary-purple: #8454FD;
  --primary-yellow: #BAA93F;
  --primary-black: #000;
  --dark-gray: #424242;
  --light-gray-bg: #F8F9FD;
  --dashboard-red: rgba(213, 0, 0, .75);
  --dashboard-blue: rgba(69, 87, 187, .75);
  --dashboard-blue-no-opacity: rgba(69, 87, 187, 1);
  --dashboard-green: rgba(0, 170, 0, .75);
  --dashboard-orange: rgba(213, 96, 0, .75);
  --dashboard-light-blue: rgba(0, 213, 207, .75);
  --dashboard-teal: rgba(0, 128, 128, .75);
  --dashboard-tan: rgba(186, 169, 69, .75);

  // Grays
  --c-white: #ffffff;
  --c-gray-100: #f9f9f9;
  --c-gray-99: #f5f5f5;
  --c-gray-98: #f3f3f3;
  --c-gray-95: #ededed;
  --c-gray-90: #e3e3e3;
  --c-gray-87: #d7d7d7;
  --c-gray-85: #d9d9d9;
  --c-gray-82: #d7d7d7;
  --c-gray-80: #cccccc;
  --c-gray-75: #c0c0c0;
  --c-gray-70: #b0b0b0;
  --c-gray-60: #999999;
  --c-gray-50: #808080;
  --c-gray-45: #727272;
  --c-gray-40: #696969;
  --c-gray-30: #4d4d4d;
  --c-gray-20: #363636;
  --c-gray-15: #222222;
  --c-gray-10: #171717;
  --c-black: #000000;

  --c-warning: #fff3cd;
  --c-blue: #4657BB;
  --c-green: #61BF57;
  --c-light-red: rgba(213, 0, 0, .15);
  --c-light-blue: rgba(70, 87, 187, .15);
  --c-light-green: rgba(97, 191, 87, .15);

  --modern-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
