@use '@angular/material' as mat;
@use 'color-palette';
@use '../responsive.mixin.scss' as responsive;
@use "sass:map";

@mixin card($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  mat-card.mat-mdc-card {
    padding: .875rem .875rem 0;

    &.padding-bottom {
      padding-bottom: .875rem;
    }

    &.no-margin {
      margin: 0;
    }
  }

  // card styling
  div,
  form,
  mat-card {
    &.card, &.mat-mdc-card {
      @include mat.elevation(2);
      background-color: #fff;
      margin: 2px;
      border-radius: 0;

      .mat-mdc-card-content {
        padding: 0;
      }

      .mat-divider {
        margin: .5rem 0 0 0;
      }

      &.elev-1 {
        @include mat.elevation(1);
        margin: 2px;
        border-radius: 0;
      }

      h2 {
        font-weight: 400;
        font-size: 1.5rem;
      }

      h3 {
        margin: 0;
        font-weight: 400;
        font-size: 1.1rem;
      }

      .card-header, .card-content {
        &.no-top-padding {
          padding-top: 0;
        }
        padding: 0.5rem .8rem
      }

      .card-header {
        padding-bottom: 0;

        //.mdc-icon-button {
        //  width: 2.25rem;
        //  height: 2rem;
        //  line-height: 1rem;
        //
        //  .mat-icon {
        //    font-size: 1rem;
        //    width: 1.2rem;
        //    margin-top: -4px;
        //  }
        //}
      }
    }

    &.content-hidden .card-header {
      padding-bottom: 8px;
    }

    &.mat-mdc-card-header-text {
      margin: 0;
    }

    &.clear {
      background-color: transparent;
    }

    &.card,
    &.clear {
      &.no-padding {
        padding: 0;
      }
    }
  }

  .flat-card {
    border: 1px solid var(--c-gray-80);
    border-radius: 4px;
    padding: 10px;
    min-height: auto;
    margin-top: 12px;
    cursor: pointer;
    max-width: 225px;
    max-height: 225px;
    height: 96px;

    &.small {
      max-width: 100%;
    }

    .flat-card-title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    &.selected {
      background-color: var(--primary-red);
      color: var(--c-white);

      &:hover {
        background-color: var(--primary-red);
      }
    }

    &:hover {
      background-color: var(--c-gray-95);
    }
  }

  .card.floating-card, ee-expansion-panel.floating-card {
    @media screen and (min-width: responsive.$break-md) {
      width: 750px;
    }

    &.medium {
      @media screen and (min-width: responsive.$break-md) {
        width: 600px;
      }
    }

    @media screen and (max-width: responsive.$break-md - 1) {
      width: 100%;
    }
  }

  .floating-section {
    @media screen and (min-width: responsive.$break-md) {
      width: 750px;
    }

    &.medium {
      @media screen and (min-width: responsive.$break-md) {
        width: 600px;
      }
    }

    @media screen and (max-width: responsive.$break-md - 1) {
      width: 100%;
    }
  }
}
