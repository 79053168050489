@use '@angular/material' as mat;
@use 'color-palette';
@import '../responsive.mixin.scss';

@mixin checkbox($theme) {
  @include mat.checkbox-density(-3);

  @media screen and (max-width: var(--break-md) - 1) {
    .mat-mdc-checkbox {
      .mat-mdc-checkbox-layout {
        .mat-mdc-checkbox-label {
          white-space: normal;
        }
      }
    }
  }

  .mat-mdc-checkbox.compact {
    --mdc-checkbox-state-layer-size: 32px;

    .mdc-checkbox {
      flex: 0 0 16px;
      height: 16px;

      .mdc-checkbox__ripple {
        width: 30px;
        height: 30px;
      }
    }

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
    }
  }
}
