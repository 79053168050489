@use '@angular/material' as mat;
@use "sass:map" as map;

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-12-opacity: rgba(black, 0.12);
$white-12-opacity: rgba(white, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);

/**
 * Copy of material designs theme function, but this allows us to set the app-bar color
 */
@function portal-light-theme($primary, $accent, $warn: mat.m2-define-palette(mat.$red-palette), $app-bar-background: white, $app-bar-color: black) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: (
      base: black,
      divider: $black-12-opacity,
      dividers: $black-12-opacity,
      disabled: rgba(black, 0.38),
      disabled-button: rgba(black, 0.38),
      disabled-text: rgba(black, 0.38),
      hint-text: rgba(black, 0.38),
      secondary-text: rgba(black, 0.54),
      icon: rgba(black, 0.54),
      icons: rgba(black, 0.54),
      text: rgba(black, 0.87),
      slider-min: rgba(black, 0.87),
      slider-off: rgba(black, 0.26),
      slider-off-active: rgba(black, 0.38),
      app-bar: $app-bar-color
    ),
    background: (
      status-bar: map.get(mat.$m2-grey-palette, 300),
      app-bar: $app-bar-background,
      background: white,
      hover: rgba(black, 0.04),
      card: white,
      dialog: white,
      disabled-button: $black-12-opacity,
      raised-button: white,
      focused-button: $black-6-opacity,
      selected-button: map.get(mat.$m2-grey-palette, 300),
      selected-disabled-button: map.get(mat.$m2-grey-palette, 400),
      disabled-button-toggle: map.get(mat.$m2-grey-palette, 200),
      unselected-chip: map.get(mat.$m2-grey-palette, 300),
      disabled-list-option: map.get(mat.$m2-grey-palette, 200)
    )
  );
}

/**
 * Copy of material designs theme function, but this allows us to set the app-bar color
 */
@function portal-dark-theme($primary, $accent, $warn: mat.m2-define-palette(mat.$red-palette), $app-bar-background: #424242, $app-bar-color: white) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: (
      base: white,
      divider: $white-12-opacity,
      dividers: $white-12-opacity,
      disabled: rgba(white, 0.3),
      disabled-button: rgba(white, 0.3),
      disabled-text: rgba(white, 0.3),
      hint-text: rgba(white, 0.3),
      secondary-text: rgba(white, 0.7),
      icon: white,
      icons: white,
      text: white,
      slider-min: white,
      slider-off: rgba(white, 0.3),
      slider-off-active: rgba(white, 0.3),
      app-bar: $app-bar-color
    ),
    background: (
      status-bar: black,
      app-bar: $app-bar-background,
      background: #303030,
      hover: rgba(white, 0.04),
      // TODO(kara): check style with Material Design UX
      card: map.get(mat.$m2-grey-palette, 800),
      dialog: map.get(mat.$m2-grey-palette, 800),
      disabled-button: $white-12-opacity,
      raised-button: map.get(mat.$m2-grey-palette, 800),
      focused-button: $white-6-opacity,
      selected-button: map.get(mat.$m2-grey-palette, 900),
      selected-disabled-button: map.get(mat.$m2-grey-palette, 800),
      disabled-button-toggle: black,
      unselected-chip: map.get(mat.$m2-grey-palette, 700),
      disabled-list-option: black
    )
  );
}
