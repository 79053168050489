@use 'color-palette';
//@import '@material/button/mdc-button';
//@import '@material/button/mixins';

@mixin portal-button($theme) {
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  button.ee__button {
    &.mat-mdc-button.mat-mdc-button-base, &.mat-mdc-raised-button.mat-mdc-button-base,
    &.mat-mdc-unelevated-button.mat-mdc-button-base, &.mat-mdc-outlined-button.mat-mdc-button-base {
      height: 38px;
      border-radius: 2px;
      font-size: 16px;
      min-width: 100px;
    }

    &.mdc-icon-button.mat-mdc-icon-button {
      --ee-btn-color: var(--primary-red);
      --ee-btn-bg-color: #FFFFFF;
      --ee-btn-bg-hover-color: #D7D7D7;
      background-color: var(--ee-btn-bg-color);
      color: var(--ee-btn-color);
      height: 24px;
      width: 24px;
      padding: 0;

      &:disabled {
        opacity: 50%;
      }

      &.green {
        --ee-btn-color: var(--primary-green);
      }

      &.gray-bg {
        --ee-btn-bg-color: #F5F5F5;
        --ee-btn-bg-hover-color: #e3e3e3;
      }

      &.md-gray-bg {
        --ee-btn-bg-color: #d9d9d9;
        --ee-btn-bg-hover-color: #c0c0c0;
      }

      mat-icon {
        font-size: 0.75rem;
        line-height: .875rem;
        height: 1rem;
        width: 1rem;
      }

      &.ee__medium {
        height: 32px;
        width: 32px;

        mat-icon {
          font-size: 1rem;
          line-height: 1.75rem;
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      &.ee__large {
        height: 38px;
        width: 38px;

        mat-icon {
          font-size: 1rem;
          line-height: 1.75rem;
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      &.black {
        color: #000;
      }

      &.blue {
        color: var(--primary-blue);
      }

      &.gray {
        color: #6F6F6F;
      }

      &:hover {
        background-color: var(--ee-btn-bg-hover-color);
      }
    }
  }

  button.ee__crud.mat-mdc-icon-button.mat-mdc-button-base {
    background-color: #F5F5F5;
    color: #d50000;
    height: 30px;
    width: 30px;
    padding: 0;
    margin-left: 10px;

    //&:first-of-type {
    //  margin-left: 0;
    //}

    &:hover {
      background-color: #D7D7D7;
    }

    mat-icon.fa {
      font-size: 0.75rem;
      line-height: .875rem;
      height: 1rem;
      width: 1rem;
    }

    &.smaller {
      height: 24px;
      width: 24px;
    }
  }

  .mdc-button > .mat-icon {
    height: 1.5rem !important;

    &.fa {
      font-size: 1.125rem;
      line-height: 1.675rem;
      width: 1.25rem;
    }
  }

  .mat-mdc-button-base.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
  }

  .mat-mdc-loading .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
