@use 'color-palette';
@use "sass:map";

@mixin quill($theme) {
  $primary-color: map.get($theme, 'color-primary');
  $secondary-color: map.get($theme, 'color-secondary');
  $warn-color: map.get($theme, 'color-warn');
  $background-color: map.get($theme, 'color-background');
  $text-color: map.get($theme, 'color-text');

  quill-editor {
    &.qh-120 {
      .ql-editor {
        max-height: 120px !important;
        min-height: 120px !important;
      }
    }

    .ql-editor {
      .mention {
        background-color: rgba(213, 0, 0, .25);
      }
    }
    &.calculated .ql-editor .mention {
      padding: 2px 4px;
      &:before, &:after {
        content: '#';
      }
    }
  }

  .ql-mention-list-container {
    background-color: #fff;
    border: 1px solid #000;
    padding: 4px 10px;

    .ql-mention-list-item {
      font-size: 14px;
      line-height: 32px;
      padding: 0 12px;

      &.selected {
        background-color: rgba(213, 0, 0, .25);
      }
    }
  }
}
