@use '@angular/material' as mat;
@use 'color-palette';
@use "sass:map";

@mixin list($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-mdc-list, .mat-mdc-selection-list, .ee__virtual-scroll-list {
    overflow: auto;
    margin-right: -0.625rem;

    &.p-0 {
      padding: 0;
    }

    &.no-right-margin {
      margin-right: 0;
    }

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100%;
    }

    .mat-mdc-list-item, .ee__virtual_scroll-list-item {
      cursor: pointer !important;
      border-bottom: 1px solid #D7D7D7;
      padding: 8px 8px 10px;
      justify-content: space-between;

      &.mat-mdc-list-option:hover::before {
        background-color: #fff;
      }

      .mdc-list-item__end {
        align-self: center !important;
      }

      .mdc-list-item__content {
        display: flex;
        flex-direction: column;
      }

      .ee__flex-item-line {
        line-height: normal;
        white-space: nowrap;
      }

      .mat-mdc-list-item-line, .ee__flex-item-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #000;

        & > div, & > span, & {
          color: rgba(0, 0, 0, 0.65);
        }

        &.ee__font-weight-bold {
          font-weight: 500 !important;
        }

        .ee__list-top-row {
          line-height: 2.5rem;
          font-weight: 600;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.empty {
        cursor: auto;
        &:hover {
          background-color: transparent;
        }
        .mdc-list-item__content {
          text-align: center;
          margin-right: 0.5rem;
        }
      }

      &:hover {
        background-color: #fff;
        opacity: 1;
      }

      &.ee__tcv_active {
        background-color: #fff;
        opacity: 1;
        color: #000;
      }
    }
  }

  .ee__virtual-scroll-list.empty {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .cdk-virtual-scroll-content-wrapper {
      height: 100%;
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      font-size: 1rem;
    }
  }

  .mat-mdc-list.searchable-list, .mat-mdc-list.styled-list {
    .mat-mdc-list-item {
      cursor: pointer;
      height: 80px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: none;

      h3, p {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .mat-mdc-line.flex {
      display: flex !important;
    }

    .mat-mdc-list-item[ng-reflect-router-link] {
      &:nth-child(even) {
        background: var(--c-gray-99);
      }

      &:nth-child(odd) {
        background: #ffffff;
      }

      &:hover {
        background-color: var(--c-gray-95);
      }

      &:focus {
        outline: none;
      }
    }

    &.styled-list {
      border: 1px solid var(--c-gray-90);
    }

    &.searchable-list, &.styled-list {
      padding: 0;
    }

    a.mat-mdc-list-item.client-row, mat-list-item.mat-mdc-list-item.client-row {
      cursor: pointer;
      height: 2.5rem;
      font-size: 1rem;

      .primary {
        font-weight: 500;
      }

      &:hover {
        background-color: var(--c-gray-95);
      }

      &.selected {
        background-color: var(--primary-red);
        .mdc-list-item__primary-text {
          color: var(--c-white);
        }
      }
    }
  }

  .double-column-list {
    dl {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin: 0;
      display: flex;
    }

    dt {
      font-size: .85rem;
      min-width: 20%;
      opacity: .65;
      flex: 1;
      font-weight: 500;
    }

    dd {
      line-height: 1.2rem;
      font-size: .95rem;
      font-weight: 500;
      text-align: right;
      align-items: flex-end;

      &.multi-section {
        text-align: left;

        .option {
          margin-bottom: 4px;
          padding-bottom: 8px;
          margin-top: 8px;
          border-bottom: 1px solid #ccc;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  mat-selection-list.ee-selection-list {
    padding-top: 0 !important;

    .ee-selection-list-item {
      height: 80px;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      text-decoration: none;
      border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);

      &.ee-selected-item {
        background-color: var(--c-gray-85);
      }
    }
  }
}
