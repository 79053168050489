@use '@angular/material' as mat;
@use "sass:map";

@mixin portal-toolbar($theme) {
    $foreground: map.get($theme, foreground);

    .portal-toolbar-brand-wrapper {
        place-content: center;
        padding: 4px;
    }

    // Brand image & text
    .portal-toolbar-brand {
        height: 50px;
        padding: 8px 18px;
        box-sizing: border-box;
        object-fit: contain;
    }
    .portal-toolbar-brand-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mat-mdc-toolbar {
        color: mat.m2-get-color-from-palette($foreground, app-bar);
    }
}
