@use '@angular/material' as mat;
@use 'color-palette';
@import '@yaireo/tagify/dist/tagify.css';

@mixin tagify($theme) {

  :root {
    --primary-blue-bg: rgba(69, 87, 187, 0.15);
    --tag-bg: var(--primary-blue-bg);
    --tag-text-color: var(--primary-blue);
    --tag-remove-btn-color: #D39494;
    --tag-hover: var(--primary-blue-bg);
    --tag-border-color: #DDD;
  }

  tag-input {
    &.dark-border {
      --tag-border-color: #9E9E9E;

      .ng2-tag-input {
        border-radius: 4px;
      }

      &:focus {
        --tag-border-color: #202020;
      }
    }

    background-color: #fff;
    .ng2-tag-input {
      border: 1px solid var(--tag-border-color) !important;
      border-bottom: 1px solid var(--tag-border-color);

      &.ng2-tag-input--focused {
        border: inherit;
      }

      .ng2-tags-container {
        padding-left: 4px;
        padding-right: 4px;

        tag-input-form {
          flex: 1;
        }

        tag {
          font-size: 13px;
          padding: 4px 6px;
          height: auto;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 0.25rem;
          color: var(--tag-text-color) !important;
          background: var(--tag-bg) !important;

          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          overflow: hidden;
          outline: 0;
          cursor: pointer;
          position: relative;

          .tag-wrapper {
            flex-direction: row;
            display: flex;
            align-items: center;

            .tag__text {
              overflow: inherit;
              white-space: inherit;
            }
          }

          delete-icon {
            height: 16px;
            border-radius: 16px;
            margin-top: 0;
            width: 14px;
            margin-left: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            path {
              fill: var(--tag-text-color) !important;
            }

            &:hover {
              transform: none;
              color: #fff;
              background-color: var(--tag-remove-btn-color);

              path {
                fill: #fff !important;
              }
            }

            svg {
              height: 12px;
              text-align: right;
            }
          }
        }

        tag-input-form {
          form {
            input {
              height: 18px;
              font-size: 13px;
              padding: 0 6px;
            }
          }
        }
      }
    }
  }

  .tagify {
    width: 100%;
    background-color: #fff;
    --tag-bg: rgba(var(--primary-blue-rgb), 0.15);
    --tag-text-color: var(--primary-blue);
    --tag-remove-btn-color: var(--primary-blue);
    --tag-hover: rgba(var(--primary-blue-rgb), 0.15);

    .tagify__tag.--notAllowed {
      box-shadow: none;
      background-color: var(--tag-invalid-bg);
    }

    .tagify__tag {
      margin: 5px;

      .tagify__tag__removeBtn {
        margin-top: 2px;
        &::after {
          height: 13px;
        }
      }
    }

    .tagify__tag > div::before {
      box-shadow: none;
      background-color: var(--tag-bg);
    }

    &.full-width {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
      align-items: stretch;

      .tagify__tag {
        margin-right: 5px;
      }
    }

    .tagify__input {
      margin-left: 0;
    }
  }

  .tagify__dropdown {
    .tagify__dropdown__item {
      box-sizing: border-box;
      padding: var(--tagify-dd-item-pad);
      margin: 1px;
      white-space: pre-wrap;
      cursor: pointer;
      border-radius: 2px;
      position: relative;
      outline: none;
      max-height: 40px;
      max-width: 100%;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    footer.tagify__dropdown__item__manage-tags {
      text-align: center;
      background-color: #eee;
      padding: 4px 0;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        background-color: var(--primary-red);
        color: #fff;
      }
    }
  }
}
