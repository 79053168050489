@use 'color-palette';

@mixin dataGridColumnWidth($width) {
  max-width: $width;
  width: $width;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin dataGrid($theme) {

  .ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    color: #d50000 !important;
  }

  .ag-checkbox-input {
    cursor: pointer;
  }

  ag-grid-angular.full-width {
    .ag-root-wrapper.ag-layout-normal {
      width: 100%;
    }
  }

  .padded-paging {
    .ag-paging-panel {
      padding-right: 50px;
    }
  }

  table.mat-mdc-table {
    tr:nth-child(even) {
      background: var(--c-gray-99);
    }

    tr:nth-child(odd) {
      background: #ffffff;
    }

    tr.mat-row:hover {
      background: var(--c-gray-95);
      cursor: pointer;
    }

    tr:focus {
      outline:0;
    }

    tr.mat-header-row, tr.mat-header-row:hover {
      background: #ffffff;
    }

    td {
      border-bottom: 0;
    }
  }

  // column widths

  .cdk-column-open_date, .cdk-column-invoice_number,
  .cdk-column-amount, .cdk-column-identifier, .cdk-column-zip_code {
    @include dataGridColumnWidth(100px);
  }

  .cdk-column-status {
    @include dataGridColumnWidth(75px);
  }

  .cdk-column-property-street_address, .cdk-column-client_name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .cdk-column-qty, .cdk-column-issue_date, .cdk-column-due_date, .cdk-column-attorney_name,
  .cdk-column-sub_total, .cdk-column-total, .cdk-column-remaining_balance, .cdk-column-amount_paid {
    @include dataGridColumnWidth(90px);
  }

  .cdk-column-company_name, .invoice-tenants.cdk-column-tenants {
    @include dataGridColumnWidth(250px);
  }

  .cdk-column-property_name, .cdk-column-issue_date, .cdk-column-due_date, .cdk-column-sub_total, .cdk-column-total, .cdk-column-court_date,
  .cdk-column-date_incurred, .cdk-column-check_number, .cdk-column-closed_date, .cdk-column-tenants {
    @include dataGridColumnWidth(150px);
  }

  .cdk-column-attorney_key {
    @include dataGridColumnWidth(200px);
  }

  .cdk-column-unit_number, .cdk-column-rate, .cdk-column-state, .cdk-column-attorney_name {
    @include dataGridColumnWidth(75px);
  }

  .cdk-column-select {
    @include dataGridColumnWidth(50px);
  }

  .cdk-column-next_step, .cdk-column-payment_status {
    @include dataGridColumnWidth(175px);
  }

  .cdk-column-case_id, .cdk-column-Error, .cdk-column-status {
    @include dataGridColumnWidth(120px);
  }

  .cdk-column-type, .cdk-column-actions {
    @include dataGridColumnWidth(125px);
  }
}
