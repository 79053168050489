// styling borrowed from https://codepen.io/melnik909/pen/qPjwvq
@use '@angular/material' as mat;
@use 'color-palette';
@use "sass:map";
@use '../responsive.mixin' as responsive;

@mixin portal-timeline($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $timeline-main-color: var(--c-green);
  $timeline-padding: 65px;

  /* Timeline Filter */
  .timeline-filter {
    padding-left: $timeline-padding;
    position: relative;

    .label {
      font-size: 1rem;
      position: absolute;
      left: 0;
    }
  }

  /* Timeline */
  .timeline {
    --uiTimelineMainColor: var(--timelineMainColor, $timeline-main-color);
    --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);

    position: relative;
    padding-bottom: 16px;

    &:before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: $timeline-main-color;
      position: absolute;
      top: 10px;
    }

    &.current-step {
      &:before {
        background-color: mat.m2-get-color-from-palette($accent, default);
        border-left: 4px mat.m2-get-color-from-palette($accent, default) solid;
      }

      .timeline__box {

        .timeline__icon {
          border-style: solid;
          background-color: mat.m2-get-color-from-palette($accent, default);
          color: #fff;
        }

        .timeline__content {
          border: 2px solid rgba(213, 0, 0, .4);
          background-color: rgba(213, 0, 0, .05);

          .next-step-title {
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            text-transform: uppercase;
            color: #6F6F6F;
            margin-bottom: 10px;
          }
          .next-step-name {
            color: var(--primary-red);
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 10px;
          }
          .next-step-description {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #6F6F6F;
          }
        }
      }
    }

    .timeline__box {
      position: relative;

      .no-results {
        font-size: 1rem;
      }

      .timeline__icon {
        position: absolute;
        left: 0;
        box-sizing: border-box;
        text-align: center;
        background-color: $timeline-main-color;
        border: 1px #fff solid;
        color: #fff;
        border-radius: 30px;
        width: 36px;
        height: 36px;

        &.blue {
          border-color: var(--c-light-blue);
          background-color: var(--c-blue);
          .mat-icon {
            color: #fff;
            font-weight: 600;
          }
        }

        .mat-icon {
          line-height: 18px;
          height: 36px;
          width: 32px;
          font-size: 18px;
          padding: 0.5rem 0;
          font-weight: 100;
        }
      }

      .timeline__content {
        padding: 15px .75rem 15px 20px;
        box-shadow: var(--modern-box-shadow);
        background-color: var(--uiTimelineSecondaryColor);
        transition: background-color .6s ease-out;

        &.highlight {
          background-color: #ffd901;
          transition: background-color .6s ease-in;
        }

        &.blue {
          border-left: 3px solid var(--c-blue);
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          color: #424242;

          .little {
            font-size: 12px;
          }
        }

        .sub-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          color: #424242;
        }

        .splitter {
          color: #D7D7D7;
          margin: 0 10px;
        }

        .content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-end;
          margin-top: 15px;

          .details {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            color: #6F6F6F;
            max-width: 100%;

            blockquote {
              word-wrap: break-word;
            }

            .field-value {
              font-size: 12px;
            }

            &.center {
              align-items: center;
            }

            &.stretch {
              align-items: stretch;
            }

            span.mention {
              border-radius: 0;
              background-color: transparent;
              -webkit-user-select: all;
              user-select: all;
              color: #000;
              font-weight: bolder;
              margin: 0;

              span {
                margin: 0;
              }
            }
          }

          .actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;

            .generating {
              width: 11rem;
              white-space: nowrap;

              &:after {
                content: "Generating Document";
                font-weight: 500;
                font-size: 1rem;
                animation: {
                  name: loading-text;
                  duration: 3s;
                  iteration-count: infinite;
                }
              }
            }

            @keyframes loading-text {
              0% {
                content: "Generating Document"
              }
              25% {
                content: "Generating Document."
              }
              50% {
                content: "Generating Document.."
              }
              75% {
                content: "Generating Document..."
              }
            }
          }
        }

        button.ee__view-invoice-button {
          width: 130px;
          padding: 0 10px;
          margin-top: 10px;
        }

        .mat-table {
          margin-top: 12px;
          width: 100%;
        }

        .expand-collapse {
          font-size: 14px;
          margin-top: 12px;
          cursor: pointer;
          text-align: center;
          font-weight: 500;
        }

        .custom-field {
          display: flex;
          flex-direction: row;
          padding-right: 5px;
          margin-right: 5px;

          &:last-of-type {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
          }

          .field-value {
            margin-bottom: .5rem;
          }
        }
      }
    }
  }

  .timeline__box:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .timeline__box:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: $timeline-main-color;

    position: absolute;
    left: 0;
    z-index: -1;
  }

  @media screen and (min-width: responsive.$break-md) {
    .timeline:before {
      left: 16px;
    }

    .timeline__group {
      padding-top: 55px;
    }

    .timeline__box {
      padding-left: $timeline-padding;
    }

    .timeline__box:before {
      top: 50%;
      transform: translateY(-50%);
    }

    .timeline__date {
      top: 50%;
      margin-top: -27px;
    }
  }

  @media screen and (max-width: responsive.$break-md) {
    .timeline:before {
      left: 0;
    }

    .timeline__group {
      padding-top: 40px;
    }

    .timeline__box {
      padding-left: 20px;
      padding-top: 10px;
    }

    .timeline__box:before {
      top: 90px;
    }

    .timeline__date {
      top: 0;
    }

    .timeline .timeline__box .timeline__icon {
      left: -17px;
    }

    .timeline .timeline__box .timeline__content .content {
      flex-direction: column;
      align-items: flex-start;

      .actions {
        margin-top: 15px;
      }

      button.ee__crud:first-of-type {
        margin-left: 0;
      }
    }
  }

  .timeline {
    --timelineMainColor: $timeline-main-color;
    font-size: 16px;
  }
}
